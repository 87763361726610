<template lang="pug">
    .row
        div(:class="{'col-sm-8': column, 'col-md-6 col-md-offset-3': !column}")
            OptiReportSearch(v-if="!first_department_ids"
                url="reports/task-sale"

                :extra-search-params="{department_ids: department_ids}"
                :loading="loading"

                @get-search="getSearch"
                @get-data="getData"
                @update-loading="l => loading = l")
                template
                    OptiButton(style="margin-right:10px" :class="{'active': filter}" v-show="is_central_department"
                        @click.native="filter = !filter") {{'report.tasksale.FILTERS'|translate}}
                        template(#icon)
                            i.fa.fa-filter

                    OptiButton(:class="{'active': column}" @click.native="column = !column") {{'report.tasksale.COLUMNS'|translate}}
                        template(#icon)
                            i.fa.fa-cog

            .form.form-horizontal
                .form-group(v-show="filter")
                    OptiCheckboxDepartment(
                        :all="true"
                        :recently-closed="true"
                        :ids="department_ids"

                        @department-ids="updateDepartmentIds")

        .col-sm-4(style="padding-left:30px" v-show="column")
            .bold(style="font-size:1.2em") {{'report.tasksale.COLUMNS'|translate}}
            div(v-for="column in columns")
                .checkbox.c-checkbox.inline
                    label
                        input(type="checkbox" :id="column.key" :name="column.key" v-model="column.selected")
                        span.fa.fa-check
                label(:for="column.key") {{column.lang|translate}}

        .col-xs-12(v-show="!loading")
            .panel.panel-primary(style="margin-bottom:0")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'report.tasksale.table.HEADING'|translate}}
                    .pull-right(v-show="from && to") {{from}} - {{to}}

                .table-responsive
                    table.table.table-condensed.table-bordered.table-striped
                        thead
                            tr
                                th.text-right Id
                                th(v-show="is_central_department") {{'report.tasksale.table.DEPARTMENT'|translate}}
                                th(v-show="!is_central_department") {{'report.tasksale.table.USER'|translate}}
                                th(v-show="isColumnActive('default')") {{'report.tasksale.table.DEFAULT'|translate}}
                                th(v-show="isColumnActive('count')") {{'report.tasksale.table.TASKCOUNT'|translate}}
                                th(v-show="isColumnActive('pricing')") {{'report.tasksale.table.TASKVALUE'|translate}}
                                th(v-show="isColumnActive('pricing_avg')") {{'report.tasksale.table.TASKVALUEAVG'|translate}}
                                th(v-show="isColumnActive('count_per_day')") {{'report.tasksale.table.TASKCOUNTDAY'|translate}}
                                th(v-show="isColumnActive('avg_per_day')") {{'report.tasksale.table.TASKVALUEDAY'|translate}}
                                th(v-show="isColumnActive('sale_value')") {{'report.tasksale.table.SALEVALUE'|translate}}
                                th(v-show="isColumnActive('sale_wholesale')") {{'report.tasksale.table.SALEWHOLESALE'|translate}}
                                th(v-show="isColumnActive('saleoffstock_value')") {{'report.tasksale.table.SALEOFFSTOCK'|translate}}
                                th(v-show="isColumnActive('register')")
                                    span {{'report.tasksale.table.REGISTER'|translate}}
                                    small(style="display:block") {{'report.tasksale.table.TODAY'|translate}}
                                th(v-show="isColumnActive('turn_over')") {{'schedule.budget.table.head.TURNOVER'|translate}}

                        tbody.text-right
                            tr(v-for="task in tasks" v-show="task.default || noactive" :class="{'text-muted': !task.default}")
                                td {{task.id}}
                                td.text-left {{task.department}}
                                td(v-show="isColumnActive('default')") {{task.default|currency}}
                                td(v-show="isColumnActive('count')") {{task.count}}
                                td(v-show="isColumnActive('pricing')")
                                    a.link(:href="$state.href('app.tasks.all', {search: ['created:>=:' + from, 'created:<=:' + to, filter_column + ':=:' + task.filter, 'status:>:notaccepted']})"
                                        target="_blank") {{task.pricing|currency}}
                                td(v-show="isColumnActive('pricing_avg')") {{task.pricing_avg|currency}}
                                td(v-show="isColumnActive('count_per_day')") {{task.count_per_day|number(2)}}
                                td(v-show="isColumnActive('avg_per_day')") {{task.avg_per_day|currency}}
                                td(ng-show="isColumnActive('sale_value')")
                                    a.link(:href="$state.href('app.sales', {search: ['date:>=:' + from, 'date:<=:' + to, filter_column + ':=:' + task.filter]})"
                                        target="_blank") {{task.sale_value|currency}}
                                td(v-show="isColumnActive('sale_wholesale')") {{task.sale_wholesale|currency}}
                                td(v-show="isColumnActive('saleoffstock_value')")
                                    a.link(:href="$state.href('app.sales-offstock', {search: ['date:>=:' + from, 'date:<=:' + to, filter_column_offstock + ':=:' + task.filter]})"
                                        target="_blank") {{task.saleoffstock_value|currency}}
                                td(v-show="isColumnActive('register')") {{task.register|currency}}
                                td(v-show="isColumnActive('turn_over')") {{task.turn_over}}

                            tr(v-show="!tasks.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                        tfoot.text-right.bold(v-if="sum")
                            tr.bg-separator
                                td
                                td
                                td(v-show="isColumnActive('default')") {{sum.default|currency}}
                                td(v-show="isColumnActive('count')") {{sum.count}}
                                td(v-show="isColumnActive('pricing')") {{sum.pricing|currency}}
                                td(v-show="isColumnActive('pricing_avg')") {{sum.pricing_avg|currency}}
                                td(v-show="isColumnActive('count_per_day')")
                                td(v-show="isColumnActive('avg_per_day')")
                                td(v-show="isColumnActive('sale_value')") {{sum.sale_value|currency}}
                                td(v-show="isColumnActive('sale_wholesale')") {{sum.sale_wholesale|currency}}
                                td(v-show="isColumnActive('saleoffstock_value')") {{sum.saleoffstock_value|currency}}
                                td(v-show="isColumnActive('register')") {{sum.register|currency}}
                                td(v-show="isColumnActive('turn_over')") {{sum.turn_over}}

            div(style="padding-bottom:10px")
                span.text-muted
                    i.fa.fa-exclamation-circle.mr3
                    span {{'report.tasksale.info.TASKS'|translate}}
                .warn.pull-right {{'report.tasksale.notify.CURRENT_DATA'|translate}}

        .col-xs-12(style="margin-bottom:15px" v-show="!loading && permission")
            a.link(@click="noactive = true" v-show="is_central_department && !noactive") {{'report.tasksale.table.toggle.SHOW'|translate}}
            a.link(@click="noactive = false" v-show="is_central_department && noactive") {{'report.tasksale.table.toggle.HIDE'|translate}}

            OptiButton.pull-right(style="margin-left:30px" v-show="is_central_department" @click.native="goXLSOptometrist"
                ) {{'report.tasksale.XLSOPT'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o
            OptiButton.pull-right(style="margin-left:30px" v-show="is_central_department" @click.native="goXLSAllUsers"
                ) {{'report.tasksale.XLSSALERS'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o
            OptiButton.pull-right(@click.native="goXLS") {{'report.tasksale.XLS'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {DownloadFileHelper} from '@/js/app/vue/helpers/DownloadFileHelper'

    import OptiReportSearch from '@/js/app/vue/components/Reports/OptiReportSearch'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCheckboxDepartment from '@/js/app/vue/components/Checkbox/OptiCheckboxDepartment'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportTasksSales',
        components: {
            OptiReportSearch,
            OptiButton,
            OptiCheckboxDepartment,
            OptiDimmer
        },
        data() {
            let _lang = 'report.tasksale.table.',
                is_central_department = this.$uac.user.activeDepartmentId === 91

            return {
                is_central_department: is_central_department,

                filter: false,
                column: false,
                noactive: false,

                filter_column: is_central_department ? 'departmentId' : 'employee',
                filter_column_offstock: is_central_department ? 'departmentId' : 'saler',

                columns: [{
                    key: 'default',
                    lang: _lang + 'DEFAULT',
                    selected: true
                }, {
                    key: 'count',
                    lang: _lang + 'TASKCOUNT',
                    selected: true
                }, {
                    key: 'pricing',
                    lang: _lang + 'TASKVALUE',
                    selected: true
                }, {
                    key: 'pricing_avg',
                    lang: _lang + 'TASKVALUEAVG',
                    selected: true
                }, {
                    key: 'count_per_day',
                    lang: _lang + 'TASKCOUNTDAY',
                    selected: true
                }, {
                    key: 'avg_per_day',
                    lang: _lang + 'TASKVALUEDAY',
                    selected: true
                }, {
                    key: 'sale_value',
                    lang: _lang + 'SALEVALUE',
                    selected: true
                }, {
                    key: 'sale_wholesale',
                    lang: _lang + 'SALEWHOLESALE',
                    selected: true
                }, {
                    key: 'saleoffstock_value',
                    lang: _lang + 'SALEOFFSTOCK',
                    selected: true
                }, {
                    key: 'register',
                    lang: _lang + 'REGISTER',
                    selected: true
                }],

                search: null,

                department_ids: [],

                first_department_ids: true,

                tasks: [],
                sum: null,
                from: '',
                to: '',

                permission: this.$uac.permission('reports.task_sale'),
                loading: false
            }
        },
        mounted() {
            if(!this.is_central_department) {
                this.columns.push({
                    key: 'turn_over',
                    lang: 'schedule.budget.table.head.TURNOVER',
                    selected: false
                })
            }
        },
        methods: {
            updateDepartmentIds(ids) {
                this.department_ids = ids

                this.first_department_ids = false
            },

            isColumnActive(key) {
                let _return = false

                this.columns.forEach(column => {
                    if(column.key === key) {
                        _return = column.selected
                    }
                })

                return _return
            },

            getSearch(search) {
                this.search = search
            },
            getData(data) {
                this.tasks = data.data
                this.sum = data.sum
                this.from = data.from
                this.to = data.to
            },

            xlsFileName(type) {
                return 'task-sale/' + type + '/raport_prace_sprzedaże_' + type + '_' + this.$uac.user.id +
                    '_' + parseInt(Math.random() * 1000000) + '.xls'
            },
            goXLS() {
                DownloadFileHelper.post('reports/task-sale/xls', this.xlsFileName('ogólne'), {
                    tasks: this.tasks,
                    from: this.from,
                    to: this.to
                })
            },
            goXLSAllUsers() {
                DownloadFileHelper.post('reports/task-sale/xls/all-users', this.xlsFileName('sprzedawcy'), {
                    search: this.search,
                    department_ids: this.department_ids
                })
            },
            goXLSOptometrist() {
                DownloadFileHelper.post('reports/optometrist/xls', this.xlsFileName('optometryści'), {
                    search: this.search,
                    department_ids: this.department_ids
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    table tr > td {
        white-space: nowrap;
    }
</style>