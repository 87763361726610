<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{ title }}

        .panel-body(style="padding:0")
            table.table.table-responsive.middle
                tbody
                    template(v-for="option in options")
                        tr.main(:class="{'bg-separator': option.selected, 'bold': option.selected}"
                            @click="option.selected = !option.selected")
                            td
                                span {{'catalog.lenses.properties.heading.' + option.type|translate}}
                                i.fa.pull-right(:class="{'fa-arrow-up': option.selected, 'fa-arrow-down': !option.selected}")
                        tr(v-if="option.selected")
                            td(style="padding:0")
                                OptiHoyaItemEdit(:edit="edit" :category="option")
  </template>

<script>
    import OptiHoyaItemEdit from '@/js/app/vue/components/Dictionary/Hoya/OptiHoyaItemEdit'

    export default {
        name: 'OptiHoyaOptionsTable',
        components: {
          OptiHoyaItemEdit,
        },
        props: {
            title: {
                type: String,
                required: true
            },
            options: {
              type: Array,
              required: true
            },
            edit: {
                type: Boolean,
                required: true
            }
        },
    }
</script>

<style lang="less" scoped>
tr.main {
  &:hover {
    cursor: pointer;
    font-weight: 700;
  }

  > td > i {
    margin-top: 3px;
  }
}
</style>