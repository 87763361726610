import Vue from 'vue'

export default {
    getDiscountCard(typeId) {
        switch(typeId) {
            case 1:
                return 20
            case 2:
                return 10
        }

        return 0
    },
    setDiscount(state, product_id) {
        if(state.carts.sale.patient.id) {
            let discount = this.getDiscountCard(Object.keys(state.carts.sale.patient.loyality).length ?
                state.carts.sale.patient.loyality.typeId : 0)

            state.carts.sale.items.forEach((item, key) => {
                if(typeof product_id === 'undefined' || item.productId === product_id) {
                    if(discount) {
                        Vue.set(state.carts.sale.items[key], 'percentDiscount', discount)
                    } else {
                        Vue.set(state.carts.sale.items[key], 'percentDiscount', 0)
                        Vue.set(state.carts.sale.items[key], 'discount', 0)
                    }

                    Vue.set(state.carts.sale.items[key], 'mode', discount ? 0 : 1)
                }
            })
        }
    },

    isItemValueMinus(items) {
        let _return = false

        items.forEach(item => {
            if(item.quantity * item.product.price - item.discount < 0) {
                _return = true
            }
        })

        return _return
    },
    isInvalidCoupon(items) {
        let _return = false

        items.forEach(item => {
            if(item.is_coupon && (typeof item.codes === 'undefined' || (item.quantity !== item.codes.length))) {
                _return = true
            }
        })

        return _return
    },
    disabled(state) {
        return state.pending || !state.carts.sale.items.length || this.isItemValueMinus(state.carts.sale.items) ||
            this.isInvalidCoupon(state.carts.sale.items) || (state.carts.sale.patient_exists === 1 && !state.carts.sale.patient.id) ||
            !state.carts.sale.totals.afterDiscount
    },

    setTotal(sale) {
        let totals = {
            quantity: 0,
            value: 0,
            percentDiscount: 0,
            discount: 0,
            afterDiscount: 0,
            vat: {},
            priceWholesale: 0
        }

        sale.items.forEach(item => {
            switch(item.mode) {
                case 0: // percent
                    item.discount = item.percentDiscount === 100 ? item.product.price * item.quantity
                        : Math.ceil(item.product.price * item.quantity * item.percentDiscount) / 100
                    break

                case 1:  // value
                    if(item.product.price * item.quantity) {
                        item.percentDiscount =  Math.ceil(item.discount / (item.product.price * item.quantity) * 10000) / 100
                    }
            }

            totals.quantity += item.quantity
            totals.value += item.product.price * item.quantity
            totals.discount += item.discount
            totals.percentDiscount = (totals.discount * 100) / totals.value
            totals.afterDiscount += item.product.price * item.quantity - item.discount
            totals.afterDiscount = Math.round(totals.afterDiscount * 100) / 100
            totals.priceWholesale += item.product.priceWholesale * item.quantity

            if(!Object.keys(totals.vat).includes(item.product.vat.toString())) {
                totals.vat[item.product.vat] = 0
            }
            totals.vat[item.product.vat] += item.product.price * item.quantity - item.discount

            if(totals.quantity > 0 && !totals.afterDiscount) {
                totals.afterDiscount = 0.01
            }
        })

        return totals
    }
}
