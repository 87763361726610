import {EventBus} from '@/js/app/_bridge/EventBus'
import {API} from '@/js/app/vue/api'

import Notify from '@/js/app/vue/helpers/Notify'

export class DownloadFileHelperClass {
    post(endpoint, filename, params) {
        EventBus.$emit('ng:emit', {
            name: 'downloadable',
            data: {
                name: filename,
                status: true
            }
        })

        if(typeof params === 'undefined') {
            params = {}
        }

        params.filename = filename

        API.post(endpoint, params).catch(() => {
            Notify.error('default.notify.error.DOWNLOAD_FILE')
        })
    }
}

export const DownloadFileHelper = new DownloadFileHelperClass()