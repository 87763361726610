<template lang="pug">
    li(v-show="lock || (!lock && !category.isLocked)"
        :class="{leaf: !category.childrenWithLocked.length, active: category.id === selected.id}")
        .category-name(:class="{'text-muted': category.isLocked}" @click="goShow")
            i.fa.mr3(:class="icon")
            span.mr3 {{category.name}}
            span.text-muted {{count[category.id]}}

        ul(v-if="category.show && category.childrenWithLocked.length")
            OptiCatalogCategoryTreeList(
                v-for="c in category.childrenWithLocked"
                :key="c.id"

                :category="c"
                :selected="selected"
                :count="count"

                :lock="lock"

                @selected="cs => $emit('selected', cs)")

            li.text-success(:class="{active: selected.id === 0 && selected.parent_id === category.id}")
                .category-name(@click="goAdd")
                    i.fa.fa-plus-circle.mr3
                    span {{'catalog.edit.ADD'|translate}}
</template>

<script>
    import Vue from 'vue'

    export default {
        name: 'OptiCatalogCategoryTreeList',
        props: {
            category: {
                type: Object,
                required: true
            },
            selected: {
                type: Object,
                required: true
            },
            count: {
                type: Object,
                required: true
            },

            lock: {
                type: Boolean,
                required: true
            }
        },
        mounted() {
            Vue.set(this.category, 'show', false)
        },
        methods: {
            goShow() {
                this.category.show = !this.category.show

                this.$emit('selected', this.category)
            },
            goAdd() {
                this.$emit('selected', {
                    id: 0,
                    parentId: this.category.id,

                    name: '',
                    sort_id: 0,
                    type_id: 0,
                    lens_delivery_type_id: 0,
                    delivery_type_id: 0,
                    isLocked: false,

                    childrenWithLocked: []
                })
            }
        },
        computed: {
            icon() {
                if(this.category.childrenWithLocked.length) {
                    return this.category.show ? 'fa-folder-open text-warning' : 'fa-folder text-warning'
                }

                return 'fa-file-o'
            }
        }
    }
</script>

<style lang="less" scoped>
    li {
        > .category-name {
            cursor: pointer;
            margin-left: -300px;
            padding-left: 300px;

            &:hover {
                background-color: lightblue;
                color: #000 !important;
            }
        }

        &.active > .category-name {
            background-color: lightskyblue;
            font-weight: 700;
            text-decoration: underline;

            &:not(.text-muted) {
                color: #000;
            }
        }

        > ul {
            list-style-type: none;
            padding-left: 20px;
        }
    }
</style>