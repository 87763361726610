<template lang="pug">
    .row
        .col-xs-12(style="margin-bottom:15px")
            OptiButton(v-show="!type" @click.native="goToCatalog") {{'catalog.edit.LINK'|translate}}
                template(#icon)
                    i.icon.icon-handbag
            OptiButtonHistory.pull-right(data="category" :id="selected.id")

        .col-xs-12
            form.form-horizontal(@submit.prevent="edit")
                .form-group
                    label.col-md-4.control-label.star-required(for="name") {{'catalog.edit.form.NAME'|translate}}
                    .col-md-8
                        input#name.form-control(v-model="selected.name" required)
                        OptiValidate(f="name" :data="$v.selected.name")
                .form-group(v-show="type")
                    label.col-md-4.control-label.star-required(for="short_name") {{'catalog.edit.form.SHORT'|translate}}
                    .col-md-8
                        input#short_name.form-control(v-model="selected.short_name" disabled)
                .form-group
                    label.col-md-4.control-label.star-required(for="type") {{'catalog.edit.form.TYPE'|translate}}
                    .col-md-8
                        OptiSelectEnum#type.form-control(enum="CategoryType" :disabled="!!selected.childrenWithLocked.length"
                            :value="selected.type_id" @update-enum="updateType")
                        OptiValidate(f="type" :data="$v.selected.type_id")
                .form-group(v-if="selected.type_id !== 1")
                    label.col-md-4.control-label(for="delivery_type") {{'catalog.edit.form.DELIVERY'|translate}}
                    .col-md-8
                        OptiSelectEnum#delivery_type.form-control(
                            :key="`delivery_type_${selected.type_id}`"
                            enum="CategoryDeliveryType"
                            :disabled="!!selected.childrenWithLocked.length"
                            :value="selected.delivery_type_id"
                            @update-enum="e => selected.delivery_type_id = e")

                .form-group(v-else-if="selected.type_id === 1")
                    label.col-md-4.control-label(for="lens_delivery_type") {{'catalog.edit.form.DELIVERY'|translate}}
                    .col-md-8
                        OptiSelectEnum#lens_delivery_type.form-control(
                            :key="`lens_delivery_type_${selected.type_id}`"
                            enum="CategoryLensDeliveryType"
                            :disabled="!!selected.childrenWithLocked.length"
                            :value="selected.lens_delivery_type_id"
                            @update-enum="e => selected.lens_delivery_type_id = e")
                .col-xs-12(v-show="showLensDeliveryAlert" style="padding-left: 0; padding-right: 0;")
                    .alert.alert-danger
                        i.fa.fa-exclamation-circle.mr3
                        span {{'catalog.edit.notify.CHANGE_LENS_DELIVERY_TYPE'|translate}}

                .form-group(v-if="!type")
                    label.col-md-4.control-label(for="upload") {{'catalog.edit.form.LOGO'|translate}}
                    .col-md-8
                        OptiUpload#upload(
                            prefix="catalog"
                            :picture="true"

                            :file="logo"

                            @update-loading="l => loading.logo = l"
                            @update-file="f => logo = f")
                .form-group
                    label.col-md-4.control-label(for="description") {{'catalog.edit.form.DESCRIPTION'|translate}}
                    .col-md-8
                        textarea#description.form-control(rows="5" v-model="selected.description")
                .form-group
                    .col-md-8.col-md-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="lock"
                                        v-model="selected.isLocked" @change="$emit('lock-was-updated')")
                                    span.fa.fa-check
                            span {{'catalog.edit.form.LOCK'|translate}}
                .form-group
                    .col-md-8.col-sm-offset-4
                        OptiButtonSave(:disabled="$v.selected.$invalid || loading.logo")

        .col-xs-12(style="margin-top:20px")
            OptiCatalogCategoryFormPanels(:catalog-id="selected.id")

        .col-xs-12(style="margin-top:20px")
            OptiCatalogCategoryFormMovies(:catalog-id="selected.id")

        OptiDimmer(:active="loading.main")

</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {EnumCategoryType} from '@/js/app/vue/enums/Enums'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiCatalogCategoryFormPanels from '@/js/app/vue/components/Catalog/Edit/OptiCatalogCategoryFormPanels'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogCategoryFormMovies from '@/js/app/vue/components/Catalog/Edit/OptiCatalogCategoryFormMovies'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogCategoryFormEdit',
        components: {
            OptiButton,
            OptiButtonHistory,
            OptiSelectEnum,
            OptiValidate,
            OptiUpload,
            OptiButtonSave,
            OptiCatalogCategoryFormPanels,
            OptiCatalogCategoryFormMovies,
            OptiDimmer
        },
        props: {
            type: {
                type: Boolean,
                required: true
            },
            selected: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                logo: '../img/categories/' + this.selected.id + '.jpg',
                loading: {
                    main: false,
                    logo: false
                },
                initialLensDeliveryTypeId: this.selected.lens_delivery_type_id,
                initialTypeId: this.selected.type_id,
            }
        },
        validations: {
            selected: {
                name: {
                    required
                },
                type_id: {
                    required
                }
            }
        },
        watch: {
            'selected.id'(id) {
                this.logo = '../img/categories/' + id + '.jpg'
                this.initialLensDeliveryTypeId = this.selected.lens_delivery_type_id
                this.initialTypeId = this.selected.type_id
            },
            'selected.type_id'(newValue) {
                if(newValue === EnumCategoryType.PRODUCT.value) {
                  this.selected.lens_delivery_type_id = this.initialLensDeliveryTypeId
                }
            },
        },
        computed: {
            showLensDeliveryAlert() {
                return this.selected.lens_delivery_type_id !== this.initialLensDeliveryTypeId
                    || this.initialTypeId !== this.selected.type_id
            }
        },
        methods: {
            goToCatalog() {
                window.open(this.$state.href('app.catalog.category', {'id': this.selected.id}), '_blank')
            },

            edit() {
                this.loading.main = true

                API.post('catalog/edit', {
                    id: this.selected.id,
                    name: this.selected.name,
                    type_id: this.selected.type_id,
                    delivery_type_id: this.selected.delivery_type_id,
                    lens_delivery_type_id: this.selected.lens_delivery_type_id,
                    logo: this.logo,
                    description: this.selected.description,
                    is_locked: this.selected.isLocked,
                    type: this.type,
                    path_locks: this.selected.path_locks
                }).then(() => {
                    this.$notify.success('catalog.notify.EDIT')
                    this.initialLensDeliveryTypeId = this.selected.lens_delivery_type_id
                    this.initialTypeId = this.selected.type_id
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('catalog.notify.error.EDIT')

                    this.$emit('fail')
                })
            },

            updateType(e) {
                this.selected.type_id = e

                if(this.selected.childrenWithLocked.length || this.selected.type_id === 1) {
                    this.selected.delivery_type_id = 0
                }
            }
        }
    }
</script>