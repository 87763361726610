<template lang="pug">
    .panel.panel-primary(v-if="!loading && !clear")
        .panel-heading
            i.fa.fa-globe.mr3
            span {{'eShop.product.reserved.HEADING'|translate}}

        .panel-body
            .row
                .col-xs-12
                    i.fa.fa-lock.mr3
                    span {{'eShop.product.reserved.RESERVED'|translate}}: {{data.reserved}}
                .col-xs-12
                    span {{'eShop.product.reserved.RESERVED_IN_E_SHOP'|translate}}:
                    ul(style="list-style-type:square" v-if="Object.keys(data.e_orders).length")
                        li(v-for="(quantity, e_order_id) in data.e_orders")
                            a.link.mr3(:href="$state.href('app.eShop.order', {id: e_order_id})" target="_blank"
                                :title="'default.REFERENCETYPE'|translate") {{e_order_id}}
                            small ({{quantity}})
                    .text-muted(v-else)
                        i.fa.fa-exclamation-circle.mr3
                        span {{'default.table.EMPTY'|translate}}
                .col-xs-12(v-show="data.diff")
                    .text-center.text-danger
                        i.fa.fa-exclamation-circle.mr3
                        span {{'eShop.product.reserved.DIFF'|translate}}

</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiProductDetailsReserved',
        data() {
            return {
                data: null,

                loading: true
            }
        },
        mounted() {
            API.get('warehouse/product/'  + this.$state.params.id + '/reserved/checker').then(res => {
                this.data = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('eShop.product.reserved.notify.error.LOAD')
            })
        },
        computed: {
            clear() {
                return !this.data || (!this.data.reserved && !Object.keys(this.data.e_orders).length)
            }
        }
    }
</script>