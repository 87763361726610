<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="true")
</template>

<script>
    import {DownloadFileHelper} from '@/js/app/vue/helpers/DownloadFileHelper'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiInventoryTasksInProgress',
        components: {
            OptiDimmer
        },
        mounted() {
            let filename = 'warehouse/inventories/tasks_in_progress/prace_w_toku_' +
                moment().format('YYYY-MM-DD') + '_' + parseInt(Math.random() * 100000) + '.xls'

            DownloadFileHelper.post('warehouse/inventories/tasks-in-progress', filename)

            this.$state.previous()
        }
    }
</script>
