<template lang="pug">
    .row
        .col-xs-12(v-if="!loading && eOrder")
            #btn-group-patient-detail.row
                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                    OptiButtonHistory(data="e_shop_order" :id="eOrder.entity_id")
                .col-md-4.text-center
                    OptiButton(type="primary" v-if="permission_change_status && eOrder.store_name_key === 'SUNLOOX'"
                        @click.native="dialog_change_status = true") {{'eShop.order.change_status.HEADER'|translate}}
                        template(#icon)
                            i.fa.fa-edit

                    MountingPortal(v-if="dialog_change_status" mountTo="#vue-modal" append)
                        OptiEShopOrderDetailsDialogStatusChange(
                            :id="eOrder.entity_id"
                            :current-status="eOrder.status"

                            @save="getData"
                            @close="dialog_change_status = false")

                .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg
                    OptiButtonPrint(:title="'eShop.print.btn.TITLE'|translate"
                        url="e-shop/control" :id="eOrder.entity_id") {{'eShop.print.btn.BTN'|translate}}

            .row
                .col-sm-6
                    .panel-grid
                        .panel.panel-primary
                            .panel-heading
                                i.fa.fa-navicon.mr3
                                span {{'eShop.order.breadcrumb.ORDER'|translate}}
                                .pull-right \#{{eOrder.entity_id}}

                            .row
                                .col-sm-6 {{'eShop.order.STORE'|translate}}
                                .col-sm-6 {{eOrder.store_name_site}}
                            .row
                                .col-sm-6 {{'eShop.order.STATUS'|translate}}
                                .col-sm-6 {{eOrder.status_name|translate}}
                            .row
                                .col-sm-6 {{'eShop.order.date.CREATED_AT'|translate}}
                                .col-sm-6 {{eOrder.created_at}}
                            .row(v-if="eOrder.invoiced_at")
                                .col-sm-6 {{'eShop.order.date.INVOICED_AT'|translate}}
                                .col-sm-6 {{eOrder.invoiced_at}}
                            .row(v-if="eOrder.shipping_date")
                                .col-sm-6 {{'eShop.order.date.SHIPPED_AT'|translate}}
                                .col-sm-6 {{eOrder.shipping_date}}
                            .row(v-if="eOrder.corrected_at")
                                .col-sm-6 {{'eShop.order.date.CORRECTED_AT'|translate}}
                                .col-sm-6 {{eOrder.corrected_at}}
                            .row
                                .col-sm-6 {{'eShop.order.date.UPDATED_AT'|translate}}
                                .col-sm-6 {{eOrder.updated_at}}
                            .row
                                .col-sm-6 {{'eShop.order.shipment.METHOD'|translate}}
                                .col-sm-6 {{eOrder.shipping_description}}
                            .row(v-show="shipment_exists")
                                .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                                .col-sm-6
                                    OptiShipmentNotice(:type="9" :id="eOrder.entity_id"
                                        @shipment-exists="s => shipment_exists = s")
                .col-sm-6
                    .panel-grid
                        .panel.panel-primary
                            .panel-heading
                                i.fa.fa-money.mr3
                                span {{'eShop.order.payment.HEADING'|translate}}

                            .row
                                .col-sm-6 {{'eShop.order.payment.METHOD'|translate}}
                                .col-sm-6 {{eOrder.payment_method_name|translate}}
                            .row
                                .col-sm-6 {{'eShop.order.payment.TOTAL'|translate}}
                                .col-sm-6 {{eOrder.total|currency}}
                            .row
                                .col-sm-6 {{'eShop.order.payment.PAID'|translate}}
                                .col-sm-6
                                    span.text-success(v-if="eOrder.paid")
                                        i.fa.fa-check.mr3
                                        span {{'default.bool.YES'|translate}}
                                    span.text-danger(v-else)
                                        i.fa.fa-close.mr3
                                        span {{'default.bool.NO'|translate}}

            .row
                .col-sm-6
                    .panel-grid
                        .panel.panel-primary
                            .panel-heading
                                i.fa.fa-home.mr3
                                span {{'eShop.address.SHIPMENT'|translate}}

                            .row(v-if="eOrder.company")
                                .col-sm-6 {{'eShop.address.COMPANY'|translate}}
                                .col-sm-6 {{eOrder.company}}
                            .row(v-else)
                                .col-sm-6 {{'eShop.address.CUSTOMER'|translate}}
                                .col-sm-6 {{eOrder.first_name}} {{eOrder.last_name}}
                            .row(v-if="eOrder.phone_number")
                                .col-sm-6 {{'eShop.address.PHONE_NUMBER'|translate}}
                                .col-sm-6 {{eOrder.phone_number|phone}}
                            .row
                                .col-sm-6 {{'eShop.address.STREET'|translate}}
                                .col-sm-6 {{eOrder.street}}
                            .row
                                .col-sm-6 {{'eShop.address.CITY'|translate}}
                                .col-sm-6 {{eOrder.zip_code}} {{eOrder.city}}

            .row
                .col-xs-12
                    .panel.panel-primary
                        .panel-heading
                            i.icon.icon-eyeglasses.mr3
                            span {{'eShop.order.items.HEADING'|translate}}

                            .label.label-danger.pull-right {{eOrder.items.length}}

                        .table-responsive
                            table.table.table-striped.middle
                                thead
                                    tr
                                        th #
                                        th {{'eShop.order.items.ID'|translate}}
                                        th {{'eShop.order.items.NAME'|translate}}
                                        th {{'eShop.order.items.PRICE'|translate}}
                                        th {{'eShop.order.items.quantity.HEADING'|translate}}
                                        th {{'eShop.order.items.TRANSFERS'|translate}}

                                tbody
                                    tr(v-for="(item, key) in eOrder.items")
                                        td.text-right {{eOrder.items.length - key}}
                                        td.text-right
                                            OptiProduct(:product="item.product") {{item.product.id}}
                                        td
                                            OptiProduct(:product="item.product") {{item.product.name}}
                                            small(style="display:block") {{item.product.fullName}}
                                        td.text-right  {{item.price|currency}}
                                        td.text-right
                                            ul
                                                li(:title="'eShop.order.items.quantity.ORDERED'|translate|lower") {{item.ordered}}
                                                li(:title="'eShop.order.items.quantity.INVOICED'|translate|lower") {{item.invoiced}}
                                                li(:title="'eShop.order.items.quantity.SHIPPED'|translate|lower") {{item.shipped}}
                                                li(:title="'eShop.order.items.quantity.REFUNDED'|translate|lower") {{item.refunded}}
                                        td.text-right
                                            div(v-show="item.reserved") {{'eShop.order.items.quantity.RESERVED'|translate}} ({{item.reserved}})

                                            div(v-for="transfer in item.transfers" :class="{'warn': transfer.status === 4}")
                                                span.mr3 {{transfer.source_department}}
                                                a.mr3.link(:class="{'warn': transfer.status === 4}" :href="$state.href('app.warehouse.transfer.detail', {id: transfer.id})"
                                                    target="_blank" :title="'default.REFERENCETYPE'|translate") {{transfer.id}}
                                                span ({{transfer.quantity}})
                                                small(style="display:block" v-show="transfer.status === 4")
                                                    i.fa.fa-remove.mr3
                                                    span {{transfer.status_name}}

                                            .text-muted(v-show="!item.reserved && !item.transfers.length")
                                                i.fa.fa-remove.mr3
                                                span {{'default.table.EMPTY'|translate}}

                                    tr(v-show="!eOrder.items.length")
                                        td.text-center.text-muted(colspan="100")
                                            i.fa.fa-exclamation-circle.mr3
                                            span {{'default.table.EMPTY'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEShopOrderDetailsDialogStatusChange
        from '@/js/app/vue/components/eShop/Orders/Dialogs/OptiEShopOrderDetailsDialogStatusChange'
    import OptiButtonPrint from '@/js/app/vue/components/Button/OptiButtonPrint'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiEShopOrderDetails',
        components: {
            OptiEShopOrderDetailsDialogStatusChange,
            OptiButtonHistory,
            OptiButton,
            OptiButtonPrint,
            OptiShipmentNotice,
            OptiProduct,
            OptiDimmer
        },
        data() {
            return {
                eOrder: null,

                permission_change_status: this.$uac.permission('e_shop.change_status'),
                dialog_change_status: false,
                shipment_exists: false,
                loading: true
            }
        },
        mounted() {
            API.get('e-shop/order/' + this.$state.params.id).then(res => {
                this.getData(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(e => {
                if([412, 404].includes(e.response.status)) {
                    this.$notify.error(e.response.data.message, false)

                    this.$state.previous()
                } else {
                    this.$notify.error('eShop.order.notify.error.LOAD')
                }
            })
        },
        methods: {
            getData(data) {
                this.eOrder = data

                this.$emit('status-name-was-changed', this.eOrder.status_name)
            }
        }
    }
</script>

<style lang="less" scoped>
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        > li {
            display: inline;

            &:not(:last-child)::after {
                content: '/';
                font-size: 12px;
                color: grey;
                padding: 0 3px;
            }
        }
    }
</style>