<template lang="pug">
    span.depository-info.middle
        span(v-if="product.depository && product.depository.id !== null && typeof product.depository.id !== 'undefined'")
            span.mr3(:title="'product.depository.title.STOCK'|translate")
                i.fa.fa-fw.fa-dropbox.text-gray-light
                span {{product.depository ? product.depository.stock : 0}}
            span.mr3(:title="'product.depository.title.ORDERED'|translate")
                i.fa.fa-fw.fa-envelope.text-gray-light
                span {{product.depository ? product.depository.ordered : 0}}
            span.mr3(:title="'product.depository.title.EXPECTED'|translate")
                i.fa.fa-fw.fa-car.text-gray-light
                span {{product.depository ? product.depository.expected : 0}}
        span(v-else)
            i.fa.fa-fw.fa-close.text-gray-light(:title="'product.depository.title.NO_DEPOSITORY'|translate")

        span.mr3(:title="'product.depository.title.WAREHOUSE'|translate")
            i.fa.fa-fw.fa-building-o.text-gray-light
            span {{product.quantity}}

        span.mr3(v-show="product.reserved" :title="'product.depository.title.RESERVED'|translate")
            i.fa.fa-fw.fa-lock.text-gray-light
            span {{product.reserved}}

        span(v-if="dotColor && dotColor.color")
            .dot.mr3(:title="'Dostępność produktu'" :style="{ backgroundColor: dotColor.color}")
            span {{dotColor.week}}

        span.mr3(v-show="cartQuantity.sale" :title="'product.depository.title.cart.SALE'|translate")
            i.fa.fa-fw.fa-shopping-cart.text-success
            span {{cartQuantity.sale}}

        span.mr3(v-show="cartQuantity.order" :title="'product.depository.title.cart.ORDER'|translate")
            i.fa.fa-fw.fa-truck.text-info
            span {{cartQuantity.order}}

        span.mr3(v-show="cartQuantity.sendback" :title="'product.depository.title.cart.RETURN'|translate")
            i.fa.fa-archive.text-warning
            span {{cartQuantity.sendback}}

        i.fa.fa-fw.fa-ticket.text-gray-light(v-show="ticket" :title="'product.depository.title.TICKET'|translate")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiDepositoryInfo',
        components: {},
        props: {
            product: {
                type: Object,
                required: true
            },
            dotColor: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                ticket: false
            }
        },
        mounted() { //TODO: zastanowić się nad cachowaniem tego jak całe metkowanie będzie w VUE
            API.get('warehouse/ticket/storage', API.id(Math.random())).then(res => {
                if (res.data) {
                    res.data.details.forEach(detail => {
                        if (detail.product_id === this.product.id) {
                            this.ticket = true
                        }
                    })
                }
            }).catch(() => {
                this.$notify.error('ticket.service.notify.error.LOAD')
            })
        },
        computed: {
            cartQuantity() {
                return this.$store.getters['carts/getQuantityToDepository'](this.product.id)
            }
        }
    }
</script>

<style lang="less" scoped>
    .dot {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: inline-block;
        margin-top: 5px;
    }
</style>