<template lang="pug">
    .col.xs-12.col-sm-4
        .panel.panel-primary(style="position:relative")
            .panel-heading(style="cursor:pointer"
                @click="priorities.selected = !priorities.selected")
                i.fa.fa-navicon.mr3
                span {{title}}

            .panel-body(style="padding:0")
                table.table.table-responsive.middle
                    tbody
                        tr.main(:class="{'bg-separator': priorities.selected, 'bold': priorities.selected}"
                            @click="priorities.selected = !priorities.selected")
                            td(colspan="2")
                                span {{priorities.selected ? 'Ukryj' : 'Pokaż'}} listę
                                i.fa.pull-right(:class="{'fa-arrow-up': priorities.selected, 'fa-arrow-down': !priorities.selected}")

                        tr(v-for="priority in priorities.items"
                            :key="priority.id" v-if="priorities.selected"
                            @click="priorities.selected = false" style="cursor:pointer")
                            td.text-center {{priority.name}}
                            td {{priority.prio}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiHoyaPriorityTable',
        props: {
            title: {
                type: String,
                required: true
            },
            delivery_lens_type: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                priorities: {
                    items: null,
                    selected: false
                },
            }
        },
        mounted() {
            try {
                API.get(`dictionary/hoya/getPriorities/${this.delivery_lens_type}`).then(res => {
                    this.priorities.items = res.data
                }).finally(() => {
                    this.loading = false
                })
            } catch (e) {
                this.$notify.error('dictionary.hoya.notify.error.LOADING')
            }
        }
    }
</script>

<style lang="less" scoped>
tr.main {
    &:hover {
        cursor: pointer;
        font-weight: 700;
    }

    > td > i {
        margin-top: 3px;
    }
}
</style>