<template lang="pug">
    .opti-select-hoya-design
        VueMultiSelect(
            :id="id"
            track-by="id"
            :clearable="false"
            :placeholder="'search.hoya.design.PLACEHOLDER'|translate"
            :showLabels="false"
            :disabled="disabled"
            label="name"
            :options="design_list"
            :value="design"

            @input="c => $emit('selected', c)")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.name}}
            template(slot="option" slot-scope="props")
                span {{props.option.name}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectHoyaDesign',
        components: {
            VueMultiSelect
        },
        props: {
            id: {
                type: String,
                required: true
            },
            design: {
                type: Object,
                required: true
            },
            lens_delivery_type: {
              type: Number,
              required: true
            },
            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                design_list: []
            }
        },
        mounted() {
            API.get(`search/hoya/design/${this.lens_delivery_type}` ).then(res => {
                this.design_list = res.data;
            }).catch(() => {
                this.$notify.error('search.hoya.design.notify.error.LOAD');
            });
          }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
</style>