<template lang="pug">
	.panel.panel-primary(style="position:relative")
		.panel-heading
			i.fa.fa-navicon.mr3
			span {{'catalog.product.stockhistory.WAREHOUSEHISTORY'|translate}}

		.table-responsive
			table.table.table-striped
				thead
					tr
						th #
						th {{'catalog.product.stockhistory.AFTERCHANGESTATE'|translate}}
						th {{'catalog.product.stockhistory.CHANGESTATE'|translate}}
						th(style="min-width:140px") {{'catalog.product.stockhistory.TYPECHANGE'|translate}}
						th {{'catalog.product.stockhistory.IDDOC'|translate}}
						th {{'catalog.product.stockhistory.DETAIL'|translate}}
						th {{'catalog.product.stockhistory.DATECHANGE'|translate}}

				tbody
					tr(v-for="(log, key) in logs.slice(0, all ? logs.length : 10)")
						td.text-right {{logs.length - key}}
						td.text-right {{log.stock}}
						td.text-right {{log.stock_change}}
						td {{log.ref_type_parsed}}
						td.text-right
							OptiRefId(v-if="!hide" section="product-history" :type-id="log.ref_type" :id="log.ref_id")
							span(v-else) {{log.ref_id}}
						td(v-html="$options.filters.nl2br(log.comments)")
						td {{log.last_change|noYearSek}}

				tfoot(v-show="logs.length > 10")
					tr
						td.text-center(colspan="7")
							a.link(v-show="!all" @click="all = true") {{'default.table.show.ALL'|translate}}
							a.link(v-show="all" @click="all = false") {{'default.table.show.ONLY'|translate}} 10 {{'default.table.show.ROWS'|translate}}

		.panel-footer.text-right(v-show="$uac.permission('products.correct')")
			OptiButton(type="info" @click.native="dialogWarehouse = true") {{'catalog.product.correct.CORRECT'|translate}}
				template(#icon)
					i.fa.fa-edit

				MountingPortal(v-if="dialogWarehouse" mountTo="#vue-modal" append)
					OptiDialogProductCorrectWarehouse(
						mode="correct"

						:product="product"

						@reported="reported"
						@closed="dialogWarehouse = false")

		OptiDimmer(:active="loading")

</template>

<script>
	import {API} from '@/js/app/vue/api'

	import OptiRefId from '@/js/app/vue/components/OptiRefId'
	import OptiButton from '../../Button/OptiButton'
	import OptiDialogProductCorrectWarehouse from '@/js/app/vue/components/Warehouse/Products/Details/Dialogs/OptiDialogProductCorrectWarehouse.vue'
	import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer.vue'

	export default {
		name: 'OptiProductWarehouseHistory',
		components: {
			OptiDimmer,
			OptiDialogProductCorrectWarehouse,
			OptiButton,
			OptiRefId
		},
		props: {
			product: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				hide: ['SłS', 'Katalog'].includes(this.$uac.user.activeRole.short),
				all: false,
				dialogWarehouse: false,
				logs: [],
				loading: true
			}
		},
		mounted() {
			this.load()
		},
		methods: {
			reported(product_stock) {
				this.dialogWarehouse = false

				this.$emit('correctWarehouse', product_stock)

				this.load()
			},
			load() {
				this.loading = true

				API.get('catalog/product/warehouse/logs/' + this.$state.params.id).then(res => {
					this.logs = res.data
				}).finally(() => {
					this.loading = false
				}).catch(() => {
					this.$notify.error('catalog.product.notify.error.LOAD')
				})
			}
		}
	}
</script>