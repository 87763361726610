<template lang="pug">
    .form.form-horizontal
        .form-group
            .col-xs-4(style="padding-top:23px")
                .radio.c-radio.c-radio-nofont
                    label.text-bold
                        input(type="radio" name="type" v-model="search.type" :value="0")
                        span
                        | {{'register.history.RANGE'|translate}}
            .col-xs-8
                .form-group
                    label.col-xs-2.control-label(for="date_from") {{'register.history.FROM'|translate}}
                    .col-xs-10
                        OptiDate#date_from(:value="search.date_from" @update-date="updateDateFrom")
                        OptiValidate(f="date_from" :data="$v.search.date_from")
                .form-group
                    label.col-xs-2.control-label(for="date_to") {{'register.history.TO'|translate}}
                    .col-xs-10
                        OptiDate#date_to(:value="search.date_to" @update-date="updateDateTo")
                        OptiValidate(f="date_to" :data="$v.search.date_to")

        .form-group
            .col-xs-4
                .radio.c-radio.c-radio-nofont
                    label.text-bold
                        input(type="radio" name="type" v-model="search.type" :value="3")
                        span
                        | {{'register.history.ONEDAY'|translate}}
            .col-xs-8
                .form-group
                    .col-xs-10.col-xs-offset-2
                        OptiDate#date_one(:value="search.one_day" @update-date="updateDateOne")
                        OptiValidate(f="date_one" :data="$v.search.one_day")

        .form-group
            .col-xs-4(style="padding-top:23px")
                .radio.c-radio.c-radio-nofont
                    label.text-bold
                        input(type="radio" name="type" v-model="search.type" :value="2")
                        span
                        | {{'register.history.RANGE2'|translate}}
            .col-xs-8
                .form-group
                    .col-xs-7.col-xs-offset-2
                        select#month_years.form-control(v-model="search.month.year" @change="updateMonthYear")
                            option(v-for="y in select.years" :value="y") {{y}}
                    label.col-xs-3.bold(style="padding-top:7px" for="month_years") {{'register.history.range.YEAR'|translate}}
                .form-group
                    .col-xs-7.col-xs-offset-2
                        select#month_months.form-control(v-model="search.month.month" @change="search.type = 2")
                            option(v-for="month in select.months" :value="month.id") {{month.name|translate}}
                    label.col-xs-3.bold(style="padding-top:7px" for="month_months") {{'register.history.range.MOUNTH'|translate}}

        .form-group
            .col-xs-4(style="padding-top:23px")
                .radio.c-radio.c-radio-nofont
                    label.text-bold
                        input(type="radio" name="type" v-model="search.type" :value="4")
                        span
                        | {{'register.history.WEEKS'|translate}}
            .col-xs-8
                .form-group
                    label.col-xs-2.control-label(for="week_date_from") {{'register.history.FROM'|translate}}
                    .col-xs-10
                        OptiSelectSnapshot#week_date_from(type="from" :date="search.week.from"
                            :opposite="search.week.to" @selected="updateWeekDateFrom")
                .form-group
                    label.col-xs-2.control-label(for="week_date_to") {{'register.history.TO'|translate}}
                    .col-xs-10
                        OptiSelectSnapshot#week_date_to(type="to" :date="search.week.to"
                            :opposite="search.week.from" @selected="updateWeekDateTo")

        .form-group
            .col-xs-4(style="padding-top:23px")
                .radio.c-radio.c-radio-nofont
                    label.text-bold
                        input(type="radio" name="type" v-model="search.type" :value="5")
                        span
                        | {{'register.history.QUARTER'|translate}}
            .col-xs-8
                .form-group
                    .col-xs-7.col-xs-offset-2
                        select#quarter_years.form-control(v-model="search.quarter.year" @change="updateQuarterYear")
                            option(v-for="y in select.years" :value="y") {{y}}
                    label.col-xs-3.bold(style="padding-top:7px" for="quarter_years") {{'register.history.range.YEAR'|translate}}
                .form-group
                    .col-xs-7.col-xs-offset-2
                        select#quarter_quarter.form-control(v-model="search.quarter.quarter" @change="search.type = 5")
                            option(v-for="q in select.quarters" :value="q") {{q}}
                    label.col-xs-3.bold(style="padding-top:7px" for="quarter_quarter") {{'register.history.range.QUARTER'|translate}}

        .form-group
            .col-xs-4
                .radio.c-radio.c-radio-nofont
                    label.text-bold
                        input(type="radio" name="type" v-model="search.type" :value="1")
                        span
                        | {{'register.history.LAST'|translate}}
            .col-xs-8
                .form-group
                    .col-xs-7.col-xs-offset-2
                        OptiNumber#days(:header="$filters.translate('register.history.LAST') + ' ' + $filters.translate('register.history.DAYS')"
                            :value="search.days" @confirmed="updateDays" :required="true") {{search.days}}
                    label.col-xs-3.bold(style="padding-top:7px" for="days") {{'register.history.DAYS'|translate}}
                    OptiValidate(f="days" :data="$v.search.days")

        .form-group
            .col-xs-12
                slot

                OptiButton.pull-right(type="success" :disabled="$v.search.$invalid || loading" @click.native="goGenerate") {{'register.SEARCH'|translate}}
                    template(#icon)
                        i.fa.fa-search
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {Months} from '@/js/app/vue/helpers/Months'
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'
    import {requiredIf} from 'vuelidate/lib/validators'
    import {dateCompare, dateTodayOrPast, noZeroIf} from '@/js/vue.validators.js'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiSelectSnapshot from '@/js/app/vue/components/Select/OptiSelectSnapshot'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiReportSearch',
        components: {
            OptiDate,
            OptiValidate,
            OptiSelectSnapshot,
            OptiNumber,
            OptiButton
        },
        props: {
            url: {
                type: String,
                required: true
            },

            extraSearchParams: {
                type: Object,
                required: false
            },
            loading: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                search: {
                    type: 3,
                    date_from: moment().format('YYYY-MM-DD'),
                    date_to: moment().format('YYYY-MM-DD'),
                    one_day: moment().format('YYYY-MM-DD'),
                    month: {
                        year: parseInt(moment().format('YYYY')),
                        month: parseInt(moment().format('M'))
                    },
                    week: {
                        from: {},
                        to: {}
                    },
                    days: 1,
                    quarter: {
                        year: parseInt(moment().isoWeekYear()),
                        quarter: BudgetHelper.getQuarterByWeek(moment())
                    }
                },

                firstYear: 2016,
                startMonth: 1,

                select: {
                    years: [],
                    months: [],
                    quarters: []
                },

                first_week_date: {
                    from: true,
                    to: true
                }
            }
        },
        validations() {
            return {
                search: {
                    date_from: {
                        required: requiredIf(search => !search.type),
                        dateCompare: dateCompare('lte', this.search.date_to),
                        dateTodayOrPast: dateTodayOrPast(true)
                    },
                    date_to: {
                        required: requiredIf(search => !search.type),
                        dateCompare: dateCompare('gte', this.search.date_from),
                        dateTodayOrPast: dateTodayOrPast(true)
                    },
                    one_day: {
                        required: requiredIf(search => search.type === 3),
                        dateTodayOrPast: dateTodayOrPast(true)
                    },
                    days: {
                        noZero: noZeroIf(search => search.type === 1)
                    }
                }
            }
        },
        mounted() {
            this.$emit('get-search', this.search)

            this.generateYears()
        },
        methods: {
            generateYears() {
                API.get('register/getFirstYear').then(res => {
                    this.firstYear = res.data.firstYear
                    this.startMonth = res.data.startMonth

                    this.generateSelectYears()
                }).catch(() => {
                    console.error('Nie można pobrać roku - weźmie 2006')

                    this.generateSelectYears()
                })
            },
            generateSelectYears() {
                for(let y = parseInt(moment().isoWeekYear()); y >= parseInt(this.firstYear); --y) {
                    this.select.years.push(y)
                }

                this.generateSelectMonths()
                this.generateSelectQuarters()
                this.goGenerate()
            },
            generateSelectMonths() {
                let m = Months.get(),
                    tmp = []

                if(this.search.month.year === parseInt(moment().isoWeekYear())) {
                    let currentMonths = Months.getToMonth(moment().format('M') === '12' && moment().isoWeek() === 1 ? 1 : moment().format('M'))

                    m.forEach(month => {
                        if(currentMonths.indexOf(month) !== -1) {
                            tmp.push(month)
                        }
                    })

                    m = tmp
                    tmp = []
                }

                if(this.search.month.year === this.firstYear) {
                    let firstYearMonths = Months.getFromMonth(this.startMonth)

                    m.forEach(month => {
                        if(firstYearMonths.indexOf(month) !== -1) {
                            tmp.push(month)
                        }
                    })

                    m = tmp
                }

                if(m.indexOf(this.search.month.month) === -1) {
                    this.search.month.month = m[0]
                }

                this.select.months = Months.parse(m)
            },
            generateSelectQuarters() {
                this.select.quarters = _.range(1, (this.search.quarter.year === parseInt(moment().isoWeekYear()) ?
                    BudgetHelper.getQuarterByWeek(moment()) : 4) + 1)

                if(!this.select.quarters.includes(this.search.quarter.quarter)) {
                    this.search.quarter.quarter = this.select.quarters[0]
                }
            },

            updateDateFrom(date_from) {
                this.search.date_from = date_from

                this.search.type = 0
            },
            updateDateTo(date_to) {
                this.search.date_to = date_to

                this.search.type = 0
            },
            updateDateOne(date_one) {
                this.search.one_day = date_one

                this.search.type = 3
            },
            updateMonthYear() {
                this.generateSelectMonths()

                this.search.type = 2
            },
            updateWeekDateFrom(week_date_from) {
                this.search.week.from = week_date_from

                if(!this.first_week_date.from) {
                    this.search.type = 4
                } else {
                    this.first_week_date.from = false
                }
            },
            updateWeekDateTo(week_date_to) {
                this.search.week.to = week_date_to

                if(!this.first_week_date.to) {
                    this.search.type = 4
                } else {
                    this.first_week_date.to = false
                }
            },
            updateQuarterYear() {
                this.generateSelectQuarters()

                this.search.type = 5
            },
            updateDays(days) {
                this.search.days = days

                this.search.type = 1
            },

            goGenerate() {
                let params = {
                    search: this.search
                }

                if(typeof this.extraSearchParams !== 'undefined') {
                    params = Object.assign(params, this.extraSearchParams)
                }

                this.$emit('update-loading', true)

                API.post(this.url, params).then(res => {
                    this.$emit('get-data', res.data)
                }).finally(() => {
                    this.$emit('update-loading', false)
                }).catch(() => {
                    this.$notify.error('default.notify.error.SEARCH_REPORT')
                })
            }
        }
    }
</script>