<template lang="pug">
    button.btn(:type="btnType" :class="[typeClass]" :disabled="disabled")
        span(v-if="right")
            slot
        span.btn-label(:class="{ 'btn-label-right': right }" v-if="hasIcon")
            slot(name="icon")
        span(v-if="!right")
            slot

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogConfirm(:text="text" @confirmed="confirmed" @closed="closed")
</template>

<script>
    import OptiDialogConfirm from '@/js/app/vue/components/Button/Dialogs/OptiDialogConfirm'

    export default {
        name: 'OptiButton',
        components: {
            OptiDialogConfirm
        },
        props: {
            type: {
                type: String,
                default: 'default',
                validator(value) {
                    return ['default', 'purple', 'primary', 'info', 'warning', 'danger', 'success', 'green', 'inverse', 'pink'].includes(value)
                }
            },
            submit: {
                type: Boolean,
                default: false
            },
            right: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: '',
                validator(value) {
                    return ['', 'sm', 'xs', 'lg'].includes(value)
                }
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false
            },

            text: {
                type: String,
                default: '',
                required: false
            }
        },
        data() {
            return {
                open: false
            }
        },
        mounted() {
            if(this.text) {
                this.$el.addEventListener('click', () => {
                    this.open = true
                })
            }
        },
        methods: {
            confirmed() {
                this.open = false

                this.$emit('confirmed')
            },
            closed() {
                this.open = false
            }
        },
        computed: {
            btnType() {
                return this.submit ? 'submit' : 'button'
            },
            typeClass() {
                let type = `btn-${this.type}`,
                    icon = this.hasIcon ? 'btn-labeled' : '',
                    size = this.size ? `btn-${this.size}` : ''

                return `${type} ${icon} ${size}`
            },
            hasIcon() {
                return !!this.$slots.icon
            }
        }
    }
</script>
