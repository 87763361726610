<template lang="pug">
    .row
        .col-md-6.col-md-offset-3
            .form.form-horizontal
                .form-group
                    .col-xs-4
                    .col-xs-8
                        .form-group
                            label.col-xs-2.control-label(for="type") {{'report.sunloox.type.HEADER'|translate}}
                            .col-xs-10
                                select#type.form-control(v-model="type.value")
                                    option(v-for="t in type.select" :value="t"
                                    ) {{'report.sunloox.type.select.' + t.toUpperCase()|translate}}

            OptiReportSearch(
                url="reports/sunloox"

                :extra-search-params="{type: type.value}"
                :loading="loading"

                @get-data="getData"
                @update-loading="l => loading = l")

        .col-xs-12(v-show="!loading")
            .panel.panel-primary(style="margin-bottom:0")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span.mr3 {{'report.sunloox.table.HEADING'|translate}}
                    small ({{'report.sunloox.type.select.' + type.calculated.toUpperCase()|translate}})
                    .pull-right(v-show="from && to")
                        span {{from}} - {{to}}
                        label.label.label-danger.ml3(v-show="data.length") {{data.length}}

                .table-responsive
                    table.table.table-condensed.table-bordered.table-striped.middle
                        thead
                            tr
                                th.text-right {{'report.sunloox.table.ENTITY_ID'|translate}}
                                th.text-right {{'report.sunloox.table.product.ID'|translate}}
                                th {{'report.sunloox.table.product.NAME'|translate}}
                                th {{'report.sunloox.table.' + (type.calculated === 'orders' ? 'ORDERED' : 'REFUNDED')|translate}}
                                th {{'report.sunloox.table.price.BRUTTO'|translate}}
                                th {{'report.sunloox.table.TAX'|translate}}
                                th {{'report.sunloox.table.price.NETTO'|translate}}
                                th {{'report.sunloox.table.price.ZHC'|translate}}
                                th {{'report.sunloox.table.' + (type.calculated === 'orders' ? 'INVOICED_AT' : 'CORRECTED_AT')|translate}}

                        tbody
                            tr(v-for="d in data")
                                td.text-right
                                    a.link(:href="$state.href('app.eShop.order', {id: d.id})" target="_blank"
                                        :title="'default.REFERENCETYPE'|translate") {{d.id}}
                                td.text-right
                                    OptiProduct(:product="d.product") {{d.product.id}}
                                td
                                    OptiProduct(:product="d.product") {{d.product.name}}
                                    small(style="display:block") {{d.product.fullName}}
                                td.text-right {{d.quantity}}
                                td.text-right {{d.price_brutto|currency}}
                                td.text-right {{d.tax}}%
                                td.text-right {{d.price_netto|currency}}
                                td.text-right {{d.price_zhc|currency}}
                                td.text-right {{d.date}}

                            tr(v-show="!data.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                        tfoot.text-right.bold(v-if="sum")
                            tr.bg-separator
                                td(colspan="3")
                                td.text-right {{sum.quantity}}
                                td(colspan="5")

        .col-sm-6.text-muted(v-show="!loading")
            i.fa.fa-exclamation-circle.mr3
            span {{'report.sunloox.INFO'|translate}}
        .col-sm-6.text-right(style="margin:15px 0" v-show="!loading && data.length")
            OptiButton(@click.native="goXLS") {{'report.sunloox.BUTTON_XLS'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {DownloadFileHelper} from '@/js/app/vue/helpers/DownloadFileHelper'

    import OptiReportSearch from '@/js/app/vue/components/Reports/OptiReportSearch'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportSunLoox',
        components: {
            OptiReportSearch,
            OptiProduct,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                type: {
                    select: ['orders', 'returns'],
                    value: 'orders',
                    calculated: 'orders'
                },

                data: [],
                sum: null,
                from: '',
                to: '',

                loading: false
            }
        },
        methods: {
            getData(data) {
                this.type.calculated = data.calculated

                this.from = data.from
                this.to = data.to

                this.data = data.data
                this.sum = data.sum
            },

            goXLS() {
                let filename = 'sunloox/raport_sunloox_' + this.type.calculated + '_' +
                    parseInt(Math.random() * 1000000) + '.xls'

                DownloadFileHelper.post('reports/sunloox/xls', filename, {
                    type: this.type.calculated,

                    from: this.from,
                    to: this.to,

                    data: this.data
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    table tr > td {
        white-space: nowrap;
    }
</style>